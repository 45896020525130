import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Rest Day.  `}<strong parentName="p">{`Missed a Test Day last week or 19.4?  Make it up today from
12:30-1:30!`}</strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our new class schedule will start Monday, March 17th along with our
new Ville Bootcamp Tues-Friday at 9:30am!  Evening CrossFit classes will
now be offered Monday-Friday at 4:00, 5:00 & 6:00pm.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      